import { FullFrameControlButton } from '@canalplus/oneplayer-shared-components';
import { TControlButtonVariants } from '@canalplus/oneplayer-shared-components/dist/types/components/buttons/controls/types';
import I18n from '../../../../lang';
import { useSetIsFullFrame } from '../../context/hooks/useSetIsFullFrame';
import { useVideoState } from '../../context/hooks/useVideoState';

export type FullFrameButtonProps = {
  variant?: TControlButtonVariants;
};

function FullFrameButton({ variant }: FullFrameButtonProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const { isFullFrame } = useVideoState();
  const setIsFullFrame = useSetIsFullFrame();

  const handleClick = (): void => {
    setIsFullFrame(!isFullFrame);
  };

  return (
    <FullFrameControlButton
      ariaLabel={t('Video.fullscreen')}
      isFullFrame={isFullFrame}
      onClick={handleClick}
      variant={variant}
    />
  );
}

export default FullFrameButton;
