import { useVideoState } from './useVideoState';

/**
 * Hook that retrieves from the video state if the video is ready to play. It checks:
 * - if the waiting time before playing is over
 * - if the video is loaded
 * - if the video is not ended
 * @returns {boolean} isVideoReadyToPlay
 */
export const useIsVideoReadyToPlay = (): boolean => {
  const { isWaitingTimeBeforePlayingOver, isEnded, isLoaded } = useVideoState();
  return isWaitingTimeBeforePlayingOver && isLoaded && !isEnded;
};
