import { AlertStatus, Button, ButtonWrapperProps } from '@canalplus/dive';
import { castToEnum } from '@canalplus/mycanal-commons';
import { Binder } from '@canalplus/one-navigation';
import { AlertBox } from '@dce-front/hodor-types/modules/contextual_offer_page/definitions';
import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { useAppHistory } from '../../helpers/hooks/reactRouter';
import { MIDDLEWARE_ERROR } from '../../helpers/oneNavigation/middleware';
import I18n from '../../lang';
import { offerLocationSelector } from '../../store/slices/application-selectors';
import Alert from '../Alert/Alert';
import styles from './Error.css';

export type ErrorTemplateProps = {
  title?: string;
  text?: string;
  alertBox?: AlertBox;
  buttonLabel?: string;
  binderLayer?: number;
  className?: string;
  error?: Error | null;
  isBackButtonHidden?: boolean;
  renderWrapperButton?: (props: ButtonWrapperProps) => React.ReactElement;
};

function ErrorTemplate({
  title,
  text,
  isBackButtonHidden = false,
  buttonLabel,
  renderWrapperButton,
  binderLayer,
  className,
  error,
  alertBox,
}: ErrorTemplateProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const history = useAppHistory();
  const offerLocation = useSelector(offerLocationSelector);
  const homePathname =
    offerLocation === getPublicConfig().defaultLocale.offerLocation
      ? '/'
      : `/${offerLocation}/`;
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const buttonProps = renderWrapperButton
    ? { renderWrapper: renderWrapperButton }
    : { onClick: () => history.push(homePathname) };

  return (
    <Binder
      {...(binderLayer ? { layer: binderLayer } : {})}
      className={classNames('errorPage', styles.errorPage, className)}
      forceFocusOnMount
      middleware={MIDDLEWARE_ERROR}
    >
      <h1 className={styles.errorPage__title}>
        {title || t('ErrorMessages.genericErrorTitle')}
      </h1>
      {alertBox?.label ? (
        <Alert
          status={castToEnum(AlertStatus)(alertBox?.state)}
          message={alertBox?.label}
          className={styles.errorPage__alert}
        />
      ) : (
        <p className={styles.errorPage__text}>
          {text || t('ErrorMessages.genericErrorTxt')}
        </p>
      )}
      {!!error && (
        <>
          {!showMoreInfo && (
            <Button
              className={styles.errorPage__button}
              variant="secondary"
              width="full"
              onClick={() => setShowMoreInfo(!showMoreInfo)}
              font="hind"
            >
              {t('ErrorTemplate.moreInfo')}
            </Button>
          )}
          {!!showMoreInfo && (
            <div className={styles.errorPage__moreInfos}>
              <p>{error?.message}</p>
              <p className={styles.errorPage__moreInfos__stack}>
                {error?.stack}
              </p>
            </div>
          )}
        </>
      )}
      {!isBackButtonHidden && (
        <Button
          className={styles.errorPage__button}
          width="full"
          {...buttonProps}
        >
          {buttonLabel || t('ErrorTemplate.backToHome')}
        </Button>
      )}
    </Binder>
  );
}

export default ErrorTemplate;
