export enum ActionType {
  RESET_VALUES = 'RESET_VALUES',
  SET_IS_ENDED = 'SET_IS_ENDED',
  SET_IS_LOADED = 'SET_IS_LOADED',
  SET_IS_FULL_FRAME = 'SET_IS_FULL_FRAME',
  SET_IS_PAUSED = 'SET_IS_PAUSED',
  SET_IS_WAITING_TIME_BEFORE_PLAYING_OVER = 'SET_IS_WAITING_TIME_BEFORE_PLAYING_OVER',
  SET_PLAYER_INSTANCE = 'SET_PLAYER_INSTANCE',
  SET_KEEP_FULL_FRAME_ON_RESET = 'SET_KEEP_FULL_FRAME_ON_RESET',
}

type ActionResetValue = {
  type: ActionType.RESET_VALUES;
};

type ActionSetIsEnded = {
  type: ActionType.SET_IS_ENDED;
  payload: {
    isEnded: boolean;
  };
};

type ActionSetIsFullFrame = {
  type: ActionType.SET_IS_FULL_FRAME;
  payload: {
    isFullFrame: boolean;
  };
};

type ActionSetIsPaused = {
  type: ActionType.SET_IS_PAUSED;
  payload: {
    isPaused: boolean;
  };
};

type ActionSetIsLoaded = {
  type: ActionType.SET_IS_LOADED;
  payload: {
    isLoaded: boolean;
  };
};

type ActionSetIsWaitingTimeBeforePlayingOver = {
  type: ActionType.SET_IS_WAITING_TIME_BEFORE_PLAYING_OVER;
  payload: {
    isWaitingTimeBeforePlayingOver: boolean;
  };
};

type ActionSetPlayerInstance = {
  type: ActionType.SET_PLAYER_INSTANCE;
  payload: {
    playerInstance: any;
  };
};

type ActionSetKeepFullFrameOnReset = {
  type: ActionType.SET_KEEP_FULL_FRAME_ON_RESET;
  payload: {
    keepFullFrameOnReset: boolean;
  };
};

export type VideoAction =
  | ActionResetValue
  | ActionSetIsEnded
  | ActionSetIsFullFrame
  | ActionSetIsPaused
  | ActionSetIsLoaded
  | ActionSetIsWaitingTimeBeforePlayingOver
  | ActionSetPlayerInstance
  | ActionSetKeepFullFrameOnReset;

export const resetValues = (): ActionResetValue => ({
  type: ActionType.RESET_VALUES,
});

export const setIsEnded = (isEnded: boolean): ActionSetIsEnded => ({
  type: ActionType.SET_IS_ENDED,
  payload: { isEnded },
});

export const setIsFullFrame = (isFullFrame: boolean): ActionSetIsFullFrame => ({
  type: ActionType.SET_IS_FULL_FRAME,
  payload: { isFullFrame },
});

export const setIsPaused = (isPaused: boolean): ActionSetIsPaused => ({
  type: ActionType.SET_IS_PAUSED,
  payload: { isPaused },
});

export const setIsLoaded = (isLoaded: boolean): ActionSetIsLoaded => ({
  type: ActionType.SET_IS_LOADED,
  payload: { isLoaded },
});

export const setIsWaitingTimeBeforePlayingOver = (
  isWaitingTimeBeforePlayingOver: boolean
): ActionSetIsWaitingTimeBeforePlayingOver => ({
  type: ActionType.SET_IS_WAITING_TIME_BEFORE_PLAYING_OVER,
  payload: { isWaitingTimeBeforePlayingOver },
});

export const setPlayerInstance = (
  playerInstance: any
): ActionSetPlayerInstance => ({
  type: ActionType.SET_PLAYER_INSTANCE,
  payload: { playerInstance },
});

export const setKeepFullFrameOnReset = (
  keepFullFrameOnReset: boolean
): ActionSetKeepFullFrameOnReset => ({
  type: ActionType.SET_KEEP_FULL_FRAME_ON_RESET,
  payload: { keepFullFrameOnReset },
});
