import { useContext } from 'react';
import { VideoContext, videoContext } from '../context';
/**
 * Hook to get the Video context
 * Throws an error if the context is not available
 */
export const useVideoContext = (): VideoContext => {
  const context = useContext(videoContext);

  if (context === null) {
    throw new Error('Video context is not defined');
  }

  return context;
};
