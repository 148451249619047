import { SoundControlButton } from '@canalplus/oneplayer-shared-components';
import { useVideoState } from '../../context/hooks/useVideoState';

/**
 * This component will check if the playerInstance.player is defined to avoid null error exception on SoundControlButton
 * when player was destroyed
 */
export function SoundControlButtonWrapper(): JSX.Element | null {
  const { playerInstance } = useVideoState();

  if (!playerInstance?.player) {
    return null;
  }

  return <SoundControlButton variant="video" />;
}
