import { VideoState } from './types';

export const INITIAL_STATE: VideoState = {
  isEnded: false,
  isFullFrame: false,
  isLoaded: false,
  isPaused: true,
  isWaitingTimeBeforePlayingOver: false,
  keepFullFrameOnReset: false,
  playerInstance: null,
};
