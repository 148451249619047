import { useAppDispatch } from '../../../../helpers/hooks/useAppDispatch';
import { setIsEmbeddedVideoFullscreenOpened } from '../../../../store/slices/ui';
import { setIsFullFrame as setIsFullFrameContext } from '../actions';
import { useVideoDispatch } from './useVideoDispatch';

export const useSetIsFullFrame = (): ((value: boolean) => void) => {
  const dispatch = useAppDispatch();
  const videoDispatch = useVideoDispatch();

  const setIsFullFrame = (value: boolean) => {
    dispatch(setIsEmbeddedVideoFullscreenOpened(value));
    videoDispatch(setIsFullFrameContext(value));
  };

  return setIsFullFrame;
};
