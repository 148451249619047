import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useScript } from 'usehooks-ts';
import Logger from '../../../../helpers/logger/logger-helper';
import { getOnePlayerMinimalUrl } from '../../../../helpers/url/url-helper';
import { platformSelector } from '../../../../store/slices/application-selectors';

type UseLoadOnePlayerMinimalScriptReturn = { isLoaded: boolean };

export function useLoadOnePlayerMinimalScript(): UseLoadOnePlayerMinimalScriptReturn {
  const platform = useSelector(platformSelector);
  const onePlayerMinimalSrc = getOnePlayerMinimalUrl(platform);

  const status = useScript(onePlayerMinimalSrc, {
    removeOnUnmount: false,
    id: 'onePlayerMinimal',
  });

  useEffect(() => {
    if (status === 'error') {
      Logger.error('[Video] load OnePlayerMinimal');
    }
  }, [status]);

  return { isLoaded: status === 'ready' };
}
