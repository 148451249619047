import { useState } from 'react';
import { useTimeout } from 'usehooks-ts';
import { setIsWaitingTimeBeforePlayingOver } from '../context/actions';
import { useVideoDispatch } from '../context/hooks/useVideoDispatch';

type UseCoverTimeoutProps = {
  enabled?: boolean;
  duration?: number;
};

/**
 * Hook to manage the cover TTL (Time To Live) before the video starts playing.
 * @returns {boolean}  - True if the timeout is over
 */
export const useCoverTimeFrame = ({
  enabled = true,
  duration = 2000,
}: UseCoverTimeoutProps): { isTimeFrameOver: boolean } => {
  const [isTimeFrameOver, setIsTimeFrameOver] = useState(false);
  const videoDispatch = useVideoDispatch();

  // Timeout to give time to the cover to be displayed before the video starts playing
  useTimeout(
    () => {
      if (!isTimeFrameOver && enabled) {
        videoDispatch(setIsWaitingTimeBeforePlayingOver(true)); // In context
        setIsTimeFrameOver(true); // Locally
      }
    },
    // If not visible, clear any running timeout with `null`
    !enabled ? null : duration
  );

  return { isTimeFrameOver };
};
